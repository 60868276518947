<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <b-row class="mb-2">
        <b-col md="6">
          <h5 class="text-uppercase heading">Cottage Booking List</h5>
        </b-col>
      </b-row>
      <div class="search-booking-list">
        <form>
          <b-row>
            <b-col md="4">
              <label class="text-muted">Select Farm Retreat</label>
              <b-form-select v-model="form._projectID">
                <b-form-select-option value=""
                  >Select Farm Retreat</b-form-select-option
                >
                <b-form-select-option
                  :value="project._projectID._id"
                  v-for="(project, index) in Projects"
                  :key="index"
                  >{{ project._projectID.name }}</b-form-select-option
                >
              </b-form-select>
            </b-col>

            <b-col md="4">
              <label class="text-muted">Booking Date (From) </label>
              <b-form-datepicker
                placeholder="Booking Date (From)"
                id="example-datepicker"
                v-model="form.BookingDateFrom"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                class="mb-2"
                required
              ></b-form-datepicker>
            </b-col>

            <b-col md="4">
              <label class="text-muted">Booking Date (To)</label>
              <b-form-datepicker
                id="example-datepicker1"
                placeholder="Booking Date (To)"
                v-model="form.BookingDateTo"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                class="mb-2"
                required
              ></b-form-datepicker>
            </b-col>

            <b-col md="6" class="mt-2">
              <label class="text-muted">Check In Date</label>
              <b-form-datepicker
                placeholder="Check-in date"
                id="example-datepicker3"
                v-model="form.CheckInDate"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                class="mb-2"
                required
              ></b-form-datepicker>
            </b-col>

            <b-col md="6" class="mt-2">
              <label class="text-muted">Check Out Date</label>
              <b-form-datepicker
                placeholder="Check-out date"
                id="example-datepicker4"
                v-model="form.CheckOutDate"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                class="mb-2"
                required
              ></b-form-datepicker>
            </b-col>
            <b-col md="3"></b-col>
            <b-col md="6" class="mt-2 mb-3">
              <b-button @click="getProjectResult" block variant="success"
                >Search</b-button
              >
            </b-col>
            <b-col md="3"></b-col>
          </b-row>
        </form>
      </div>
      <div class="booking-list">
        <b-row class="mb-4">
          <b-col md="2">
            <!-- Select User Per Page  -->
            <b-form-select v-model="item_per_page" @change="changeItemNumber">
              <b-form-select-option value="10">10</b-form-select-option>
              <b-form-select-option value="50">50</b-form-select-option>
              <b-form-select-option value="100">100</b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col md="10" class="d-flex justify-content-end">
            <b-button variant="success" @click="downloadCSV">
              <i class="fa fa-download" aria-hidden="true"></i>
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="dataTable rounded-left"
          :outlined="true"
          :items="data"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-desc.sync="sortDesc"
          head-variant="none"
          table-variant="none"
          :busy="isBusy"
          sticky-header="1200px"
          :caption-top="true"
          :responsive="true"
          :no-border-collapse="false"
          show-empty
        >
          <template #empty="scope">
            <h4>{{ scope.emptyText }}</h4>
          </template>
          <template #cell(#)="data">
            <span v-if="currentPage == 1">{{ data.index + 1 }}</span>
            <span v-else>{{
              (currentPage - 1) * item_per_page + (data.index + 1)
            }}</span>
          </template>
          <template #cell(Farms)="row">
            <span v-if="row.item._projectID">{{
              row.item._projectID.name
            }}</span>
          </template>
          <template #cell(Room)="row">
            <span v-if="row.item._RoomID">{{ row.item._RoomID.name }}</span>
          </template>
          <template #cell(checkInDate)="row">
            {{ row.item.checkInDate | formatDate }}
          </template>
          <template #cell(checkOutDate)="row">
            {{ row.item.checkOutDate | formatDate }}
          </template>
          <template #cell(DandN)="row">
            {{ row.item.days }} D {{ row.item.nights }} N
          </template>
          <template #cell(bookingDate)="row">
            {{ row.item.createdAt | moment('dddd, MMMM Do YYYY') }}
          </template>
          <template #cell(Member)="row">
            <span v-if="row.item._userID">{{ row.item._userID.name }}</span>
          </template>
          <template #cell(Status)="row">
            <b-badge variant="success" v-if="row.item.status == true"
              >Confirmed</b-badge
            >
            <b-badge variant="danger" v-if="row.item.status == false"
              >Cancelled</b-badge
            >
          </template>

          <template #cell(actions)="row">
            <div class="btn btn-group">
              <router-link
                :to="'/farm-retreat-bookings/' + row.item._id"
                class="btn btn-sm btn-primary"
                >View
              </router-link>
              <button
                class="btn btn-sm btn-danger"
                v-if="row.item.status == true"
                @click="cancelBooking(row.item._id)"
              >
                Cancel
              </button>
            </div>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-center mt-4">
          <b-pagination
            pills
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  components: {},
  name: 'BookingList',
  data() {
    return {
      item_per_page: 10,
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      isBusy: false,
      form: {
        _projectID: '',
        BookingDateFrom: '',
        BookingDateTo: '',
        checkInDate: '',
        CheckOutDate: '',
      },
      fields: [
        '#',
        {
          key: 'bookingID',
          label: 'Booking ID',
        },
        'Farms',
        'Room',
        {
          key: 'checkInDate',
          label: 'Check In Date',
        },
        {
          key: 'checkOutDate',
          label: 'Check Out Date',
        },
        {
          key: 'DandN',
          label: 'Days & Nights',
        },
        {
          key: 'Adult',
          label: 'Adult',
        },
        {
          key: 'Child',
          label: 'Child',
        },
        {
          key: 'Rooms',
          label: 'Cottage',
        },
        {
          key: 'bookingDate',
          label: 'Booking Date',
        },
        'Member',
        'Status',
        {
          key: 'Price',
          label: 'Credits',
        },
        'actions',
      ],
      users: [],
      Projects: [],
      data: [],
      rooms: [],
      bookings: [],
    };
  },
  mounted() {
    this.getProjects();
    this.getProjectResult();
  },
  computed: {
    rows() {
      return this.data.length;
    },
  },
  methods: {
    changeItemNumber() {
      this.perPage = this.item_per_page;
    },
    cancelBooking(id) {
      let resp = confirm('Are you sure want to cancel the booking?');
      if (resp) {
        axios
          .post('booking-cancellation', { booking_id: id })
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              alert(resp.data.message);
            } else {
              alert(resp.data.message);
            }
            this.getProjectResult();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getProjectResult() {
      this.isBusy = true;
      axios.get('farm-resort-booking', { params: this.form }).then((resp) => {
        console.log(resp);
        this.data = resp.data.data;
        this.isBusy = false;
      });
    },
    getProjects() {
      axios.get('project-resorts').then((resp) => {
        console.log(resp);
        this.Projects = resp.data.data;
      });
    },

    downloadCSV() {
      let csv =
        '\ufeff' +
        'BOOKING ID,FARMS,ROOM,CHECK IN DATE,CHECK OUT DATE,DAYS AND NIGHTS,ADULT,CHILD,COTTAGE,BOOKING DATE,MEMBER,STATUS,CREDITS\n';
      this.data.forEach((el) => {
        let userName = el['_userID'] ? el['_userID'].name : '';
        let farmName = el['_projectID'] ? el['_projectID'].name : '';
        let roomName = el['_RoomID'] ? el['_RoomID'].name : '';
        let status = el['status'] ? 'Confirmed' : 'Cancelled';
        var line =
          el['bookingID'] +
          ',' +
          farmName +
          ',' +
          roomName +
          ',' +
          `${moment(el['checkInDate']).format('DD-MM-YYYY')}` +
          ',' +
          `${moment(el['checkOutDate']).format('DD-MM-YYYY')}` +
          ',' +
          `${el['days']}D ${el['nights']}N` +
          ',' +
          el['Adult'] +
          ',' +
          el['Child'] +
          ',' +
          el['Rooms'] +
          ',' +
          `${moment(el['createdAt']).format('DD-MM-YYYY')}` +
          ',' +
          userName +
          ',' +
          status +
          ',' +
          el['Price'] +
          ',' +
          '\n';
        csv += line;
      });
      var blob = new Blob([csv], { type: 'csv/plain' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Cottage_Booking_List.csv';
      link.click();
    },
  },
};
</script>

<style scoped>
.heading {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 3.5%;
  margin-top: 5px;
  margin-left: 4px;
  margin-bottom: 5px;
  color: #454545;
}

/deep/ .dataTable thead tr th {
  background-color: #e6e6e6;
  height: 51px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: 'Poppins', sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
}

/deep/ .dataTable {
  text-align: center;
  margin-bottom: 21.5px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
/deep/ .dataTable tr td {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
